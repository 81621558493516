import { useState } from "react";
import "./Invite.css";
import sundowns from "./img/msfc.png"
import team2 from "./img/orlando-pirates.png";
import t_logo from "./img/logo.png";
import emailjs from "emailjs-com";

const Invite = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [email, setEmail] = useState("");
  const [diet, setDietary] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");

  const handleRSVP = (e) => {
    e.preventDefault();

    if (
      firstname.trim().length < 1 ||
      lastname.trim().length < 1 ||
      phonenumber.trim().length < 1 ||
      email.trim().length < 1 ||
      diet.trim().length < 1
    ) {
      setErrMessage("All fields are compulsory!");
      setTimeout(() => {
        setErrMessage("");
      }, 5000);
    } else {
      emailjs
        .sendForm(
          "service_x19jhyf",
          "template_x4lrf28",
          e.target,
          "i61nlpJO3MtsJBeAP"
        )
        .then((res) => {
          setSuccMessage("Email Sent Successfully.");
          setTimeout(() => {
            setSuccMessage("");
            setFirstname("");
            setLastname("");
            setPhonenumber("");
            setEmail("");
          }, 5000);
        })
        .catch((err) => {
          setErrMessage("Email sending failed, please try again.");
          console.log(err);
          setTimeout(() => {
            setErrMessage("");
          }, 5000);
        });
    }
  };

  return (
    <div className="invite">
      <div className="bg"></div>
      <div className="tdts__logo">
        <a href="/">
          <h1 className="logo">
            <img src={t_logo} alt="logo" />
          </h1>
        </a>
      </div>
      <div className="bann">
        <span>CELEBRATING OVER 20 YEARS OF EXCELLENCE</span>
      </div>
      <div className="row">
        <div className="col__3"></div>
        <div className="col__3">
          <div className="teams">
            <div className="logo__1">
              <img src={sundowns} alt="sundowns" className="sundowns" />
            </div>
            <div className="logo__2">
              <img src={team2} alt="team_2" className="team_2" />
            </div>
          </div>
          <div className="e__message">
            <p className="err__message">{errMessage}</p>
            <p className="succ__message">{succMessage}</p>
          </div>
          <div className="message">
          <span className="invite__message">    
            Please join our MD, Mogomotsi Motsielwa and our CEO, Sinah Motsielwa for a hospitality afternoon of live football match between Mamelodi Sundowns and Orlando Pirates on the 17th of February 2024 at Suite N512 Loftus Versveld Stadium in Pretoria.
          </span>
          <span>
            Suite Opens: 18:30
          </span>
          <span>
            Kick Off: 20:00
          </span>
          <span>  
            Dinner: 19:00
          </span>
          <span>  
            Suite Closes: 23:00
          </span>
          </div>
          <div className="rsvp__form">
            <form onSubmit={handleRSVP}>
              <h1 className="rsvp__heading">RSVP</h1>
              <input type="text" name="firstname" className="first__name" placeholder="Firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
              <input type="text" name="lastname" className="last__name" placeholder="Lastname" value={lastname} onChange={(e) => setLastname(e.target.value)}/>
              <input type="tel" name="phone" className="phone__number" placeholder="Phone Number" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)}/>
              <input type="email" name="email" className="email__address" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)}/>
              <select name="diet" className="diet" value={diet} onChange={(e) => setDietary(e.target.value)}>
                <option value="" className="white" hidden>Dietary Requirements</option>
                <option value="halaal">Halaal</option>
                <option value="kosher">Kosher</option>
                <option value="celiac-disease">Celiac Disease(Gluten Free)</option>
                <option value="peanut-allergies">Peanut Allergies</option>
                <option value="vegetarian">Vegetarian and Vegans</option>
                <option value="lactose-intolerance">Lactose Intolerance</option>
                <option value="none">None</option>
              </select>
              <div className="buttons">
                <button className="rsvp btn">
                  <b>RSVP</b>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col__3"></div>
      </div>
    </div>
  );
};

export default Invite;
